import { t } from '@lingui/macro'
import { TradeMarginEnum, TradeModeEnum } from './common'

export enum TradeStopSideTypeEnum {
  /** 单向 */
  single = 'single',
  /** 双向 */
  double = 'double',
}

export const getTradeStopSideTypeEnumMap = () => {
  return {
    [TradeStopSideTypeEnum.single]: t`constants_trade_581svvbtse`,
    [TradeStopSideTypeEnum.double]: t`constants_trade_uqstkea22u`,
  }
}

export type ITradeSpotTabs = TradeMarginEnum | TradeModeEnum
