import { create } from 'zustand'

import { createTrackedSelector } from 'react-tracked'
import produce from 'immer'
import { getFuturePerpetualList } from '@/apis/future/common'
import {
  getFuturesCurrentLeverageCache,
  getFuturesTradeUnit,
  getGroupMarginSourceCacheMap,
  getTakerFuturesCurrentLeverageCache,
  getTakerGroupMarginSourceCacheMap,
  setFuturesCurrentLeverageCache,
  setFuturesTradeUnit,
  setGroupMarginSourceCacheMap,
  setTakerFuturesCurrentLeverageCache,
  setTakerGroupMarginSourceCacheMap,
} from '@/helper/cache'
import { ISubscribeParams } from '@/plugins/ws/types'
import { WsTypesEnum, WsBizEnum, WsThrottleTimeEnum } from '@/constants/ws'
import { WSThrottleTypeEnum } from '@nbit/chart-utils'
import contractWs from '@/plugins/ws/futures'
import { subscribeWithSelector } from 'zustand/middleware'
import { envIsClient } from '@/helper/env'
import { FuturesGuideIdStepsEnum, defaultLevel } from '@/constants/future/trade'
import { getResetLever } from '@/helper/trade'
import { getAssetsTakerAccountList } from '@/apis/assets/copy-trading/taker'
import { FuturesAccountResp } from '@/typings/api/assets/futures'
import { TradeMarketAmountTypesEnum } from '@/constants/trade/common'
import { TradeFuturesOrderAssetsTypesEnum } from '@/constants/trade/futures'
import { FuturesTradeModeEnum } from '@/constants/future/common'
import { baseContractMarketStore } from './market/contract'
import { baseUserStore } from './user'
import { baseAssetsFuturesStore } from './assets/futures'

const futuresCurrentLeverageCache = getFuturesCurrentLeverageCache()
const takerFuturesCurrentLeverageCache = getTakerFuturesCurrentLeverageCache()
const groupMarginSourceCacheMap = getGroupMarginSourceCacheMap()
const takerGroupMarginSourceCacheMap = getTakerGroupMarginSourceCacheMap()
const futuresTradeUnit = getFuturesTradeUnit()

let subscribed = false

function createOnWs(_, get) {
  return {
    async onContractGroupListPush() {
      const state = get()
      const groupList = await state.getContractGroupList()
      const {
        tradeModeType,
        selectedContractGroup,
        updateContractGroup,
        selectedTakerContractGroup,
        updateTakerContractGroup,
      } = get()

      const isCommonMode = tradeModeType === FuturesTradeModeEnum.common
      const selectFutures = isCommonMode ? selectedContractGroup : selectedTakerContractGroup
      if (selectFutures?.groupId) {
        const selectedGroup = groupList?.find(item => item?.groupId === selectFutures?.groupId)
        isCommonMode ? updateContractGroup(selectedGroup) : updateTakerContractGroup(selectedGroup)
      }
    },
  }
}

type IStore = ReturnType<typeof getStore>

function getStore(set, get) {
  const state = {
    tradePanel: {
      tradeUnit: futuresTradeUnit || TradeMarketAmountTypesEnum.amount,
    },
    updateTradeUnit: val =>
      set(
        produce((draft: IStore) => {
          draft.tradePanel.tradeUnit = val
          setFuturesTradeUnit(val)
        })
      ),
    groupMarginSourceCache:
      groupMarginSourceCacheMap || ({} as Record<string | number, TradeFuturesOrderAssetsTypesEnum>),
    updateGroupMarginSource: val =>
      set(
        produce((draft: IStore) => {
          draft.groupMarginSourceCache[`${draft.selectedContractGroup?.groupId}`] = val
          setGroupMarginSourceCacheMap(draft.groupMarginSourceCache)
        })
      ),
    // 带单开仓保证金来源
    takerGroupMarginSourceCache:
      takerGroupMarginSourceCacheMap || ({} as Record<string | number, TradeFuturesOrderAssetsTypesEnum>),
    updateTakerGroupMarginSource: val =>
      set(
        produce((draft: IStore) => {
          draft.takerGroupMarginSourceCache[`${draft.selectedTakerContractGroup?.groupId}`] = val
          setTakerGroupMarginSourceCacheMap(draft.takerGroupMarginSourceCache)
        })
      ),
    isClickOrderBook: false,
    setIsClickOrderBook: val =>
      set(
        produce((draft: IStore) => {
          draft.isClickOrderBook = val
        })
      ),
    openQuestionsMsg: '',
    setOpenQuestionsMsg: val =>
      set(
        produce((draft: IStore) => {
          draft.openQuestionsMsg = val
        })
      ),
    currentGroupOrderAssetsTypes: TradeFuturesOrderAssetsTypesEnum.assets,
    setCurrentGroupOrderAssetsTypes: val =>
      set(
        produce((draft: IStore) => {
          draft.currentGroupOrderAssetsTypes = val
        })
      ),
    /** 带单账户资产类型 */
    takerCurrentGroupOrderAssetsTypes: TradeFuturesOrderAssetsTypesEnum.assets,
    setTakerCurrentGroupOrderAssetsTypes: val =>
      set(
        produce((draft: IStore) => {
          draft.takerCurrentGroupOrderAssetsTypes = val
        })
      ),
    currentLeverageCache: (futuresCurrentLeverageCache || {}) as Record<string, number>,
    currentLeverage: defaultLevel,
    setCurrentLeverage: val =>
      set(
        produce((draft: IStore) => {
          draft.currentLeverageCache[`${baseContractMarketStore.getState().currentCoin.symbolName}`] = val
          draft.currentLeverage = val
          setFuturesCurrentLeverageCache(draft.currentLeverageCache)
        })
      ),
    /** 带单合约杠杆 */
    takerCurrentLeverageCache: (takerFuturesCurrentLeverageCache || {}) as Record<string, number>,
    takerCurrentLeverage: defaultLevel,
    setTakerCurrentLeverage: val =>
      set(
        produce((draft: IStore) => {
          draft.takerCurrentLeverageCache[`${baseContractMarketStore.getState().currentCoin.symbolName}`] = val
          draft.takerCurrentLeverage = val
          setTakerFuturesCurrentLeverageCache(draft.takerCurrentLeverageCache)
        })
      ),
    selectedContractGroup: { groupName: '' } as FuturesAccountResp,
    updateContractGroup: contractGroup =>
      set(
        produce((draft: IStore) => {
          draft.selectedContractGroup = contractGroup
        })
      ),
    /** 选择带单合约账户 */
    selectedTakerContractGroup: { groupName: '' } as FuturesAccountResp,
    updateTakerContractGroup: newTakerContractGroup =>
      set(
        produce((draft: IStore) => {
          draft.selectedTakerContractGroup = newTakerContractGroup
        })
      ),
    /** 合约交易模式 */
    tradeModeType: FuturesTradeModeEnum.common,
    setTradeModeType: (val: FuturesTradeModeEnum) =>
      set(
        produce((draft: IStore) => {
          draft.tradeModeType = val
        })
      ),
    contractGroupList: [] as FuturesAccountResp[],
    async getContractGroupList(mode?: FuturesTradeModeEnum) {
      const { tradeModeType } = get()
      const { updateUserAssetsTakerFutures } = baseAssetsFuturesStore.getState()
      const { userInfo } = baseUserStore.getState()

      const isCommonMode = (mode || tradeModeType) === FuturesTradeModeEnum.common
      const { isOk, data } =
        !isCommonMode && userInfo?.isCopyTradingLeader
          ? await getAssetsTakerAccountList({})
          : await getFuturePerpetualList({})
      const groupList = data?.list

      if (isOk) {
        set(
          produce((draft: IStore) => {
            draft.contractGroupList = groupList || []

            if (!isCommonMode && userInfo?.isCopyTradingLeader) {
              updateUserAssetsTakerFutures(data?.accountAvailableAsset || '0')
            }
          })
        )
        return groupList
      }
      return false
    },
    contractGroupSubscribe() {
      if (subscribed) {
        return () => {}
      }
      const { onContractGroupListPush } = createOnWs(set, get)
      const subscribeParams: ISubscribeParams[] = [
        {
          subs: {
            biz: WsBizEnum.perpetual,
            type: WsTypesEnum.perpetualGroupDetail,
          },
          throttleTime: WsThrottleTimeEnum.Market,
          throttleType: WSThrottleTypeEnum.increment,
          callback: onContractGroupListPush,
        },
      ]
      subscribeParams.forEach(({ callback, ...params }) => {
        contractWs.subscribe({
          ...params,
          callback,
        })
      })
      subscribed = true
      return () => {
        subscribed = false
        subscribeParams.forEach(params => {
          contractWs.unsubscribe(params)
        })
      }
    },
    isFutureShow: false,
    setIsFutureShow: (val: boolean) =>
      set(
        produce((draft: IStore) => {
          draft.isFutureShow = val
        })
      ),
    // 合约引导图
    futureEnabled: false,
    setFutureEnabled: (val: boolean) =>
      set(
        produce((draft: IStore) => {
          draft.futureEnabled = val
        })
      ),
    // 当前引导图索引
    currentIntroId: FuturesGuideIdStepsEnum.none,
    setCurrentIntroId: (val: number) =>
      set(
        produce((draft: IStore) => {
          draft.currentIntroId = val
        })
      ),
    // 重新调用引导图
    resetIntroEnabled: (num?: number) =>
      set(
        produce((draft: IStore) => {
          draft.futureEnabled = true
          draft.currentIntroId = num || FuturesGuideIdStepsEnum.none
        })
      ),
    // 预备调用，因为在资产开通直接调方法会导致引导图先加载但是相关 dom 没加载出来
    readyCallIntroId: false,
    setReadyCallIntroId: (val: boolean) =>
      set(
        produce((draft: IStore) => {
          draft.readyCallIntroId = val
        })
      ),
  }

  return state
}
const baseFuturesStore = create(subscribeWithSelector(getStore))
baseFuturesStore.subscribe(
  state => state.selectedContractGroup?.groupId,
  groupId => {
    if (envIsClient) {
      const store = baseFuturesStore.getState()
      const type = store.groupMarginSourceCache[`${groupId}`] || TradeFuturesOrderAssetsTypesEnum.assets
      store.setCurrentGroupOrderAssetsTypes(type)
    }
  }
)
/**
 * 杠杆默认值逻辑
 */
baseContractMarketStore.subscribe(
  state => state.currentCoin?.symbolName,
  symbolName => {
    if (envIsClient && symbolName) {
      const store = baseFuturesStore.getState()
      const { currentLeverageCache, takerCurrentLeverageCache, setCurrentLeverage, setTakerCurrentLeverage } = store

      const max = getResetLever(baseContractMarketStore.getState().currentCoin?.tradePairLeverList)
      setCurrentLeverage(currentLeverageCache[`${symbolName}`] || max)
      setTakerCurrentLeverage(takerCurrentLeverageCache[`${symbolName}`] || max)
    }
  }
)
/** 登录状态改变后判断当前用户合约组缓存是否合法，然后清空 */
if (envIsClient) {
  baseUserStore.subscribe(
    state => state.isLogin,
    async isLogin => {
      if (envIsClient && isLogin) {
        const store = baseFuturesStore.getState()
        const groupList = await store.getContractGroupList()
        const {
          selectedContractGroup,
          updateContractGroup,
          selectedTakerContractGroup,
          updateTakerContractGroup,
          tradeModeType,
        } = store
        const isCommon = tradeModeType === FuturesTradeModeEnum.common
        const groupId = isCommon ? selectedContractGroup?.groupId : selectedTakerContractGroup?.groupId
        if (groupId) {
          const selectedGroup = groupList && groupList?.find(item => item?.groupId === groupId)
          isCommon ? updateContractGroup(selectedGroup) : updateTakerContractGroup(selectedGroup)
        }
      }
    }
  )
}
const useFuturesStore = createTrackedSelector(baseFuturesStore)

export { useFuturesStore, baseFuturesStore }
