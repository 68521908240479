import { t } from '@lingui/macro'
import { TradeMarginEnum, TradeModeEnum, TradeOrderTypesEnum } from './common'

/** 合约交易类型 */
export enum TradeFuturesTypesEnum {
  /** 开仓 */
  open = 'open',
  /** 平仓 */
  close = 'close',
}

export const getTradeFuturesTypesMap = () => ({
  [TradeFuturesTypesEnum.open]: t`constants_trade_10`,
  [TradeFuturesTypesEnum.close]: t`constants_trade_11`,
})

/** 合约前提条件单位类型 */
export enum TradeFuturesOptionUnitEnum {
  /** 最新 */
  last = 'last',
  /** 标记 */
  mark = 'mark',
}

export const getTradeFuturesOptionUnitMap = () => ({
  [TradeFuturesOptionUnitEnum.last]: t`constants_trade_13`,
  [TradeFuturesOptionUnitEnum.mark]: t`constants_trade_12`,
})

/** 合约前提条件止盈止损类型 */
export enum TradeFuturesOptionEnum {
  /** 止盈 */
  takeProfit = 'takeProfit',
  /** 止损 */
  stopLoss = 'stopLoss',
}

export const getTradeFuturesOptionMap = () => ({
  [TradeFuturesOptionEnum.takeProfit]: t`constants/order-6`,
  [TradeFuturesOptionEnum.stopLoss]: t`constants/order-7`,
})

export const getTradeFuturesOrderTypesMap = () => {
  return {
    [TradeOrderTypesEnum.market]: t`constants/trade-3`,
    [TradeOrderTypesEnum.limit]: t`constants/trade-2`,
    [TradeOrderTypesEnum.trailing]: t`features_trade_trade_setting_index_2520`,
  }
}

/** 开仓保证金来源类型 */
export enum TradeFuturesOrderAssetsTypesEnum {
  assets = 'wallet',
  group = 'group',
}

/** 开仓保证金来源类型 */
export const getTradeFuturesOrderAssetsTypesMap = () => ({
  [TradeFuturesOrderAssetsTypesEnum.assets]: t`features_c2c_center_coin_switch_index_msuc6zmu2dxzocr_5wzmr`,
  [TradeFuturesOrderAssetsTypesEnum.group]: t`constants_trade_94qulrnz9z`,
})

/** 带单开仓保证金来源类型 */
export const getTakerTradeFuturesOrderAssetsTypesMap = () => ({
  [TradeFuturesOrderAssetsTypesEnum.assets]: t`features_future_select_account_modal_index_coc1pyija8`,
  [TradeFuturesOrderAssetsTypesEnum.group]: t`constants_trade_94qulrnz9z`,
})

/** 合约计算器收益、收益率类型 */
export enum TradeFuturesCalculatorIncomeUnitEnum {
  /** 收益 */
  incomeNumber = 'incomeNumber',
  /** 收益率 */
  incomePercent = 'incomePercent',
}

export const getTradeFuturesCalculatorIncomeUnitMap = () => ({
  [TradeFuturesCalculatorIncomeUnitEnum.incomeNumber]: t`features/orders/order-columns/future-2`,
  [TradeFuturesCalculatorIncomeUnitEnum.incomePercent]: t`features/orders/order-columns/holding-5`,
})

export type ITradeFuturesTabs = TradeMarginEnum | TradeModeEnum
