import cacheUtils from 'store'

/** 合约缓存下单交易类型 */
const tradeFuturesOrderTypes = 'TRADE_FUTURES_ORDER_TYPES'

export function getTradeFuturesOrderTypes() {
  return cacheUtils.get(tradeFuturesOrderTypes)
}

export function setTradeFuturesOrderTypes(type) {
  cacheUtils.set(tradeFuturesOrderTypes, type)
}

export const tradeFuturesOrderAssetsTypes = 'tradeFuturesOrderAssetsTypes'

export function getCacheTradeFuturesOrderAssetsTypes() {
  return cacheUtils.get(tradeFuturesOrderAssetsTypes)
}

/** 合约交易 - 普通模式 - 杠杆倍数 */
export const futuresCurrentLeverageCache = 'futuresCurrentLeverageCache'
export function getFuturesCurrentLeverageCache() {
  return cacheUtils.get(futuresCurrentLeverageCache)
}
export function setFuturesCurrentLeverageCache(val) {
  return cacheUtils.set(futuresCurrentLeverageCache, val)
}

/** 合约交易 - 带单模式 - 杠杆倍数 */
export const takerFuturesCurrentLeverageCache = 'TAKER_FUTURES_CURRENT_LEVERAGE_CACHE'
export function getTakerFuturesCurrentLeverageCache() {
  return cacheUtils.get(takerFuturesCurrentLeverageCache)
}
export function setTakerFuturesCurrentLeverageCache(val) {
  return cacheUtils.set(takerFuturesCurrentLeverageCache, val)
}

export function setCacheTradeFuturesOrderAssetsTypes(val) {
  return cacheUtils.set(tradeFuturesOrderAssetsTypes, val)
}

// 合约组点击合约页面可用的弹窗设置、资金开仓还是合约组开仓
const groupMarginSourceCacheMap = 'groupMarginSourceCacheMap'

export function getGroupMarginSourceCacheMap() {
  return cacheUtils.get(groupMarginSourceCacheMap)
}

export function setGroupMarginSourceCacheMap(type) {
  cacheUtils.set(groupMarginSourceCacheMap, type)
}

// 合约交易 - 带单模式 - 开仓保证金来源
const takerGroupMarginSourceCacheMap = 'TAKER_GROUP_MARGIN_SOURCE_CACHE_MAP'

export function getTakerGroupMarginSourceCacheMap() {
  return cacheUtils.get(takerGroupMarginSourceCacheMap)
}

export function setTakerGroupMarginSourceCacheMap(type) {
  cacheUtils.set(takerGroupMarginSourceCacheMap, type)
}

// 合约交易下单单位
const futuresTradeUnit = 'futuresTradeUnit'

export function getFuturesTradeUnit() {
  return cacheUtils.get(futuresTradeUnit)
}

export function setFuturesTradeUnit(type) {
  cacheUtils.set(futuresTradeUnit, type)
}
